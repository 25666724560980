import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import faqStyles from "../styles/pages/faq.module.css"

const FAQPage = ({ data }) => (
  <Layout location="faq">
    <SEO title="FAQ" />
    <div className={ faqStyles.header }>
      <div className={ faqStyles.headerImage }></div>
      <div className={ faqStyles.headerText }>
        <h4 className={ faqStyles.title }>FAQ</h4>
        <p className={ faqStyles.subtitle }>Frequently Asked Questions</p>
      </div>
    </div>
    <div className={ faqStyles.container }>
      <div className={ faqStyles.question }>
        <h3>When was Executive Decisions IMC Firm established?</h3>
        <hr/>
        <p>EDIMCF, nicknamed ED, was established on February 02, 1998 and was incorporated in the Year 2000. It now has more than two decades of experience and combined organizational expertise exceeding 100 years.</p>
      </div>
      <div className={ faqStyles.question }>
        <h3>What is IMC?</h3>
        <hr/>
        <p>IMC (Integrated Marketing Communications) is a simple but fascinating concept. MMC Learning® describes IMC as carefully linking together all forms of communications and messages through the integration of marketing, communication and promotional tools that should work all together in harmony.</p>
      </div>
      <div className={ faqStyles.question }>
        <h3>What are the IMC disciplines that ED has in its mix?</h3>
        <hr/>
        <p>The fields of IMC engagement that ED has in its offer plate are events, graphic arts, video production, movie prod & post-prod, talents, promo & activation, marketing PR, digital services.</p>
      </div>
      <div className={ faqStyles.question }>
        <h3>What are the benefits of adopting an IMC approach to a business, campaign or endeavor?</h3>
        <hr/>
        <p>All the marketing, communication and promotional tools in promoting a business, association, product or service perform more efficiently and effectively if they work together in harmony, complementarily or thematically rather than in isolation. This is one of the situations where “the sum is greater than its parts”.</p>
      </div>
      <div className={ faqStyles.question }>
        <h3>Why should I adopt an IMC approach for my marketing?</h3>
        <hr/>
        <p>IMC may require a lot of effort but it can deliver great benefits. It can create competitive advantage and differentiation, attract new customers, build loyal ones, boost sales and revenues, save time and money. It also increases profits through amplified effectiveness.</p>
      </div>
      <div className={ faqStyles.question }>
        <h3>It seems expensive?</h3>
        <hr/>
        <p>On the contrary, IMC will save you money as resources and assets such as artworks, video clips and rendered works can be shared, used repetitively or in multiple ways as the company promotes its products, services and causes. It also saves you time and reduces your workload and stress.</p>
      </div>
      <div className={ faqStyles.question }>
        <h3>Promoting one’s products and services has become more challenging due to the current situation, how can I still promote my business?</h3>
        <hr/>
        <p>That’s the beauty of IMC. It is not dependent on only one or two ways of getting your offering known, but there’s a plethora of tools to choose from and the configurations of using these tools number a plenty, truly allowing a business to adapt to the contextual environment one is in.</p>
      </div>
      <div className={ faqStyles.question }>
        <h3>It looks like ED is a company designed to cater to big companies, how about a small company or an entrepreneur like me?</h3>
        <hr/>
        <p>ED is a company with a sharp mind but with a bigger heart. Realizing that it is the SMEs that will need more help in marketing and communicating their products and services but could not afford the hefty fees of the traditional agency, it created Teddie the AdShop for SMEs and entrepreneurs. For more details, visit teddie.org.</p>
      </div>
      <div className={ faqStyles.question }>
        <h3>Why should I go with ED?</h3>
        <hr/>
        <p>By tapping into ED, you’ll have a dedicated team by your side that will use its creativity, expertise, resources and connections to help you succeed in your business. Service is the essence of our being, because helping out is our nature.</p>
      </div>
      <div className={ faqStyles.question }>
        <h3>Okay, so where or how do I start onboarding ED to my ship?</h3>
        <hr/>
        <p>Give us a call or drop us a line, and let’s explore how we can best collaborate. Together, we can do great things.</p>
      </div>
    </div>
  </Layout>
)

export const fullScreenWidth = graphql`
  fragment fullScreenWidth on File {
    childImageSharp {
      fluid(maxWidth: 2000) {
        aspectRatio
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const query = graphql`
  query {
    divider: file(relativePath: { eq: "divider.png" }) {
      ...fullScreenWidth
    }
  }
`

export default FAQPage